import React from "react"
import { Container, Grid, Box } from "@mui/material"
import { makeStyles } from "makeStyles"
import Link from "components/i18n/Link"

import FixedDots from "components/Layout/FixedDots"
import Button from "components/Buttons/Button"

import client from "utils/sanity/client"
import { PortableText } from "@portabletext/react"

import { imageBuilder } from "utils/sanity/client"

import SAPlaner from "svg/SA-Planer.svg"

const useStyles = makeStyles()({
	button: {
		position: "absolute",
		bottom: 15,
		left: 10,
		"&:hover": {
			background: "none",
		},
	},
	bigCardHeight: {
		height: 350,
		width: "100%",
		"@media only screen and (max-width: 600px)": {
			height: 250,
		},
	},
	bigCardImage: {
		height: 150,
		objectFit: "contain",
		position: "absolute",
		right: 10,
		bottom: 10,
		"@media only screen and (max-width: 600px)": {
			bottom: 0,
			height: 120,
		},
	},
	lockingSystemImage: {
		height: 290,
		objectFit: "contain",
		position: "absolute",
		right: 0,
		bottom: 0,
		"@media only screen and (max-width: 600px)": {
			height: 240,
			bottom: -20,
		},
	},
	//
	cards: {
		transition: "all 0.3s ease-out",
		"& $card": {
			"&::before": {
				content: '""',
				position: "absolute",
				zIndex: -1,
				top: "-26px",
				right: "-26px",
				background: "rgba(70, 145, 206, 0.3)",
				height: "32px",
				width: "32px",
				borderRadius: "32px",
				transform: "scale(1)",
				transformOrigin: "50% 50%",
				transition: "transform 0.25s ease-out",
			},

			"&:hover::before": { transform: "scale(34)", transitionDelay: "0.25s" },
		},
	},
	card: {
		display: "block",
		position: "relative",
		width: "100%",
		backgroundColor: "#ebf1f7",
		borderRadius: "4px",
		padding: "0px",
		textDecoration: "none",
		zIndex: 0,
		overflow: "hidden",
	},
})

const ATFGrid = ({ teaser }: Props) => {
	const { classes } = useStyles()

	const CtaButton = () => {
		return (
			<Button color="link" className={classes.button}>
				<span
					style={{
						color: "#4691CE",
						borderBottom: "2px solid #4691CE",
						fontSize: 13,
					}}
				>
					<strong>Anzeigen</strong>
				</span>
			</Button>
		)
	}

	const { field1, image1, field2, image2, field3, image3, field4 } = teaser

	const urlFor = (source) => {
		return imageBuilder.image(source)
	}

	return (
		<Container>
			<Box mt={3} mb={5}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6} md={4}>
						<Link href="/c/elektronische-tuerschloesser">
							<div className={classes.cards}>
								<div className={`${classes.bigCardHeight} ${classes.card}`}>
									<Box p={2}>
										<div style={{ maxWidth: "80%" }}>
											<PortableText
												value={field1}
												// imageOptions={{ w: 320, h: 240, fit: "max" }}
												{...client.config()}
											/>
										</div>

										<CtaButton />
									</Box>
									<img
										src={urlFor(image1).url()}
										alt="image1"
										className={classes.bigCardImage}
									/>
								</div>
							</div>
						</Link>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<Link href="/c/schliesszylinder">
							<div className={classes.cards}>
								<div
									className={classes.card}
									style={{
										height: 167.5,
										width: "100%",
									}}
								>
									<Box p={2}>
										<div style={{ maxWidth: "70%" }}>
											<PortableText
												value={field2}
												// imageOptions={{ w: 320, h: 240, fit: "max" }}
												{...client.config()}
											/>
										</div>

										<CtaButton />
									</Box>

									<img
										src={urlFor(image2).url()}
										alt="image2"
										style={{
											height: 90,
											objectFit: "contain",
											position: "absolute",
											right: 15,
											bottom: 5,
										}}
									/>
								</div>
							</div>
						</Link>
						<Link href="/c/smart-locks">
							<Box mt={2} className={classes.cards} style={{ position: "relative" }}>
								<div
									className={classes.card}
									style={{
										height: 167.5,
										width: "100%",
									}}
								>
									<Box p={2}>
										<div style={{ maxWidth: "80%" }}>
											<PortableText
												value={field3}
												// imageOptions={{ w: 320, h: 240, fit: "max" }}
												{...client.config()}
											/>
										</div>

										<CtaButton />
									</Box>

									<img
										src={urlFor(image3).url()}
										alt="image2"
										style={{
											height: 90,
											objectFit: "contain",
											position: "absolute",
											right: 0,
											top: 70,
										}}
									/>
								</div>
							</Box>
						</Link>
					</Grid>
					<Grid item xs={12} sm={12} md={4}>
						<Link href="/locking-system">
							<div className={classes.cards}>
								<div
									className={`${classes.bigCardHeight} ${classes.card}`}
									style={{
										background: "#FEFCE8",
									}}
								>
									<Box p={2}>
										<div style={{ maxWidth: "70%" }}>
											<PortableText
												value={field4}
												// imageOptions={{ w: 320, h: 240, fit: "max" }}
												{...client.config()}
											/>
										</div>

										<CtaButton />
									</Box>
									<SAPlaner className={classes.lockingSystemImage} />
								</div>
							</div>
						</Link>
					</Grid>
				</Grid>
			</Box>
			<FixedDots />
		</Container>
	)
}

interface Props {
	teaser: any
}

export default ATFGrid
