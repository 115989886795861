import React from "react"
import { Container, Box } from "@mui/material"
import { makeStyles } from "makeStyles"
import Link from "components/i18n/Link"

import Icon from "@mdi/react"
import { mdiHeadphones, mdiPackageDown, mdiCreditCardOutline, mdiLicense } from "@mdi/js"
import formatPrice from "@secureo/common/utils/forex/formatPrice"
import { flatFeesByCurrencyCode } from "@secureo/common/config/shippingZonesAndCosts"

const useStyles = makeStyles()({
	paper: {
		boxShadow: "none",
		display: "flex",
		alignItems: "center",
		whiteSpace: "nowrap",
		border: "1px solid whitesmoke",
		borderTop: "none",
		borderBottom: "none",
	},
})

const items = [
	{
		headline: "Kompetente Beratung",
		text: "Fragen Sie unsere Experten",
		icon: mdiHeadphones,
		url: "/help/why-choose-us",
	},
	{
		headline: "Kostenloser Versand",
		text: `Ab Einkaufswert von ${formatPrice(
			flatFeesByCurrencyCode.EUR.flatFeeForSmallAmountThresholdGrossCents,
			"de-DE",
		)}`,
		icon: mdiPackageDown,
		url: "/help/shipping-and-payment",
	},
	{
		headline: "Sichere Online-Zahlung",
		text: "inkl. 2% Vorkassenrabatt",
		icon: mdiCreditCardOutline,
		url: "/help/shipping-and-payment",
	},
	{
		headline: "Preis Leistungsverhältnis",
		text: "Höchste Qualität zum besten Preis",
		icon: mdiLicense,
		url: "/help/shipping-and-payment",
	},
]

const AboutUs = () => {
	const { classes } = useStyles()
	return (
		<Container>
			<Box
				py={3}
				px={2}
				className="scrollX-no-scrollbar"
				style={{
					border: "1px solid whitesmoke",
					background: "white",
					borderRadius: 10,
					overflowX: "scroll",
				}}
			>
				<div style={{ display: "flex", justifyContent: "space-between" }}>
					{items.map((item) => {
						const { headline, text, icon, url } = item
						return (
							<Link key={item.headline} href={url}>
								<Box style={{ width: 265 }} p={1} className={classes.paper}>
									<Icon
										path={icon}
										size={1.5}
										color="#4691CE"
										style={{ marginRight: 15 }}
									/>
									<div>
										<strong>{headline}</strong>
										<div style={{ color: "grey", fontSize: 14 }}>{text}</div>
									</div>
								</Box>
							</Link>
						)
					})}
				</div>
			</Box>
		</Container>
	)
}

export default AboutUs
