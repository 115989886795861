import dynamic from "next/dynamic"
import { GetStaticPaths, GetStaticProps } from "next"
import { Box } from "@mui/material"

import ATFGrid from "containers/HomePage/ATFGrid"
import AboutUs from "containers/HomePage/AboutUs"
import Categories from "containers/HomePage/Categories"
const BrandsSlider = dynamic(() => import("containers/HomePage/BrandsSlider"), {
	ssr: false,
})
// import OurShops from "containers/HomePage/OurShops"

import { anonymousAccessTokenCache } from "@secureo/common/utils/commerceTools/token/accessTokenCache"
import fetchBrandCategories from "@secureo/common/utils/commerceTools/graphql/queries/fetchBrandCategories"

import navCategories from "config/navCategories.json"
import { supportedValidLocales } from "@secureo/common/utils/i18n/supportedLocalesAndCurrencyCodes"

import { NavCategory } from "@secureo/common/utils/commerceTools/graphql/queries/fetchNavCategories"
import { Category } from "@secureo/common/typings/Category"
import { getTeaser } from "utils/sanity/teaser"
import serializeJSON from "@secureo/common/utils/serializeJSON"

const HomePage = ({ brandCategories, teaser }: Props) => {
	return (
		<div style={{ position: "relative", overflow: "hidden" }}>
			<ATFGrid teaser={teaser} />
			<AboutUs />
			<Categories navCategories={navCategories as NavCategory[]} />
			<Box pb={5}>
				<BrandsSlider brandCategories={brandCategories} />
			</Box>
			{/* <OurShops /> */}
		</div>
	)
}

interface Props {
	brandCategories: Category[]
	locale: string
	teaser: unknown
}

export const getStaticPaths: GetStaticPaths = async () => {
	const paths = supportedValidLocales.map((locale) => {
		return { params: { locale } }
	})

	return {
		paths,
		fallback: false,
	}
}

export const getStaticProps: GetStaticProps = async (context, preview = false) => {
	const accessToken = await anonymousAccessTokenCache()
	const locale = context.params.locale as string

	const { teaser } = await getTeaser(preview)

	const brandCategories = await fetchBrandCategories(accessToken, locale)

	const props: Props = {
		brandCategories,
		locale,
		teaser,
	}

	return { props: serializeJSON(props) }
}

export default HomePage
