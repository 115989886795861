import { createClient, ClientConfig } from "@sanity/client"
import imageUrlBuilder from "@sanity/image-url"

const options: ClientConfig = {
	// Find your project ID and dataset in `sanity.json` in your studio project
	dataset: "production",
	projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
	useCdn: process.env.NODE_ENV === "production",
	apiVersion: "2022-12-20",
	// useCdn == true gives fast, cheap responses using a globally distributed cache.
	// Set this to false if your application require the freshest possible
	// data always (potentially slightly slower and a bit more expensive).
}

const client = createClient(options)

export const imageBuilder = imageUrlBuilder(client)

export const previewClient = createClient({
	...options,
	useCdn: false,
	token: process.env.SANITY_API_TOKEN,
})

export const getClient = (preview: boolean) => (preview ? previewClient : client)

export default client
