import React from "react"
import Link from "components/i18n/Link"
import { Container, Box, Grid, List, ListItem, ListItemText, ListItemIcon } from "@mui/material"

import { makeStyles } from "makeStyles"

import { NavCategory } from "@secureo/common/utils/commerceTools/graphql/queries/fetchNavCategories"
import LazyLoadedImage from "components/LazyLoadedImage"

import Icon from "@mdi/react"
import { mdiChevronRight } from "@mdi/js"

const useStyles = makeStyles()({
	card: {
		background: "#EBF1F7",
		padding: 10,
		borderRadius: 5,
		position: "relative",
	},
	image: {
		position: "absolute",
		height: 110,
		width: 140,
		right: 20,
		bottom: 20,
		objectFit: "contain",
		mixBlendMode: "multiply",
		"@media only screen and (max-width: 600px)": {
			height: 70,
			bottom: 5,
			right: -15,
		},
	},
	button: {
		background: "none",
		border: "none",
		textDecoration: "underline",
		fontWeight: "bold",
		color: "#4691CE",
		"&:hover": {
			background: "none",
			textDecoration: "underline",
		},
	},
	categoryTitle: {
		display: "block",
		color: "#263739",
		"@media only screen and (max-width: 600px)": {
			fontWeight: 800,
			marginTop: 10,
		},
	},
	hideLinksOnMobile: {
		display: "block",
		"@media only screen and (max-width: 600px)": {
			display: "none",
		},
	},
})

// Add Background Images
const getCategoryImage = (categoryName: string) => {
	switch (categoryName) {
		case "E-Möbelschlösser":
			return "https://res.cloudinary.com/digitalzylinder-shop/image/upload/v1598858538/DigiShop2/Homepage/ps-locks-HP.png"

		case "Schlüsselmanagement":
			return "https://res.cloudinary.com/digitalzylinder-shop/image/upload/v1598858477/DigiShop2/Homepage/masunt-HP.png"

		case "Schlüsselaufbewahrung":
			return "https://res.cloudinary.com/digitalzylinder-shop/image/upload/v1598858321/DigiShop2/Homepage/masterlock-HP.png"

		default:
			return "https://res.cloudinary.com/digitalzylinder-shop/image/upload/v1594040975/DigiShop2/Homepage/E-Tu%CC%88rschlo%CC%88sser.png"
	}
}

const Categories = ({ navCategories }: Props) => {
	const { classes } = useStyles()

	const filteredCategories = navCategories.filter((navCategory) =>
		["e-moebelschloesser", "schluesselmanagement", "schluesselaufbewahrung"].includes(
			navCategory.slug,
		),
	)

	return (
		<Container>
			<Box mt={5}>
				<Box pb={3} style={{ textAlign: "center", maxWidth: 680, margin: "auto" }}>
					<div style={{ color: "#4691CE", textTransform: "uppercase", fontSize: 13 }}>
						<strong>Warum Sie bei uns kaufen sollten</strong>
					</div>
					<h1 style={{ margin: 0 }}>Sicher und günstig einkaufen!</h1>
					<p style={{ color: "grey" }}>
						Warum lange Suchen? Von elektronischen Türschlössern, über Schließzylinder
						bis hin zu Smart Locks, wir haben alle großen und namhaften Hersteller auf
						einer Seite und eine große Auwahl mit über 10.000 Produkten.{" "}
					</p>
				</Box>
				<Grid container spacing={3}>
					{filteredCategories.map((category) => {
						const { name, slug, children = [] } = category

						return (
							<Grid item xs={12} md={4} key={name}>
								<div className={classes.card}>
									<List>
										{/* First Stage */}
										<Box ml={2} mb={2}>
											<Link href={`/c/${slug}`} aria-haspopup="true">
												<div className={classes.categoryTitle}>
													<strong>{name}</strong>
												</div>
											</Link>
										</Box>
										<div className={classes.hideLinksOnMobile}>
											{children.map((child) => {
												const { name, children = [] } = child
												{
													/* Second Stage - Category Items */
												}
												if (child.name === "Nachbestellungen") return false
												const cutChildrenToMaxFive = children.slice(0, 5)
												return (
													<div
														key={name}
														style={{
															height: 180,
														}}
													>
														{cutChildrenToMaxFive.map((child) => {
															const { name, slug } = child
															return (
																<Link
																	href={`/c/${slug}`}
																	key={name}
																>
																	<ListItem
																		button
																		style={{
																			maxWidth: 360,
																		}}
																	>
																		<ListItemIcon
																			style={{
																				marginRight: -20,
																			}}
																		>
																			<Icon
																				path={
																					mdiChevronRight
																				}
																				size={1}
																				color="#505e5f"
																			/>
																		</ListItemIcon>
																		<ListItemText
																			primary={
																				<div
																					style={{
																						color: "#505e5f",
																					}}
																				>
																					<div>
																						{name}
																					</div>
																				</div>
																			}
																		/>
																	</ListItem>
																</Link>
															)
														})}
													</div>
												)
											})}
											<ListItem key={name}>
												<Link
													href={`/c/${slug}`}
													className="menu-link mega-menu-link"
													aria-haspopup="true"
												>
													<ListItemText
														primary={
															<Box
																mt={2}
																style={{ color: "#4691CE" }}
															>
																<strong>Alle Produkte</strong>
															</Box>
														}
														style={{
															color: "black",
															borderBottom: "1px solid #4691CE",
														}}
													/>
												</Link>
											</ListItem>
										</div>
									</List>

									<LazyLoadedImage
										alt={name}
										title={name}
										src={getCategoryImage(category.name)}
										className={classes.image}
										// onIntersection={onIntersection}
									/>
								</div>
							</Grid>
						)
					})}
				</Grid>
			</Box>
		</Container>
	)
}

interface Props {
	navCategories: NavCategory[]
}

export default Categories
