import React from "react"
import { makeStyles } from "makeStyles"

const useStyles = makeStyles()(() => ({
	breakPointSm: {
		display: "block",
	},
	"@media only screen and (max-width: 600px)": {
		breakPointSm: {
			display: "none",
		},
	},
}))

const FixedDots = () => {
	const { classes } = useStyles()
	return (
		<div className={classes.breakPointSm}>
			<img
				alt="background-element"
				src="https://res.cloudinary.com/digitalzylinder-shop/image/upload/v1590389116/DigiShop2/Homepage/Background-Element-ATF.png"
				style={{ position: "fixed", zIndex: -1, bottom: "10%", left: "10%" }}
			/>
		</div>
	)
}

export default FixedDots
